.content {
    display: flex;
}

.content > div {
    width: 100%;
}

.content > div + div {
    margin-left: 1rem;
}
