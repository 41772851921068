.title {
    flex: 1;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.logo {
    height: auto;
    width: 3rem;
}

.spacer {
    flex: 1;
}
